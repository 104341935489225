
<template>
  <div class="edit_insurance">
    <div class="title">编辑客户信息</div>
    <el-form :model="form" ref="form" label-width="7rem" class="ruleForm" :rules="rules">
      <el-form-item label="客户名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入客户名称" maxlength="100"></el-input>
      </el-form-item>
      <el-form-item label="纳税人识别号" prop="taxId">
        <el-input v-model="form.taxId" placeholder="请输入纳税人识别号" maxlength="20"></el-input>
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input v-model="form.email" placeholder="请输入邮箱"></el-input>
      </el-form-item>
      <el-form-item label="地址" prop="address">
        <el-input v-model="form.address" placeholder="请输入地址" maxlength="80"></el-input>
      </el-form-item>
      <el-form-item label="电话" prop="phone">
        <el-input v-model="form.phone" placeholder="请输入电话"></el-input>
      </el-form-item>
      <el-form-item label="开户行" prop="bankName">
        <el-input v-model="form.bankName" placeholder="请输入开户行" maxlength="80"></el-input>
      </el-form-item>
      <el-form-item label="开户行账号" prop="bankAccountNo">
        <el-input v-model="form.bankAccountNo" placeholder="请输入开户行账号" minlength="10" maxlength="28"></el-input>
      </el-form-item>

      <el-form-item class="btn">
        <el-button type="primary" @click="submitForm()" :loading="isLoading">保存</el-button>
        <el-button @click="back" class="cancel_btn">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { addTerminal } from '@/api/setInvoice'
export default {
  data() {
    const checkPhone = (rule, value, callback) => {
      if (value) {
        var pass = false;
        const mobile =
            /^(((\+86)|(\+886)|(\+852)|(\+853))\s+)?((13[0-9])|(14[5,7])|(15[0-3,5-9])|(17[0,3,5-8])|(18[0-9])|166|198|199|190)\d{8}$/;
        const tel = /^0\d{2,3}-?\d{7,8}$/; // 座机
        if (mobile.test(value)) {
          pass = true;
        }
        if (tel.test(value)) {
          pass = true;
        }
        if (!pass) {
          return callback(new Error("请输入公司电话(座机格式'区号-座机号码')"));
        }
      }
      callback();
    };
     const checkTax = (rule, value, callback) => {
      if (value == "") {
        return callback(new Error("请输入纳税人识别号"));
      } else if (
          value.length == 15 ||
          value.length == 16 ||
          value.length == 17 ||
          value.length == 18 ||
          value.length == 19 ||
          value.length == 20
      ) {
        const pattern = /^[A-Z0-9]+$/;
        if (!pattern.test(value)) {
          return callback(new Error("纳税人识别号只能输入数字或大写英文字母"));
        }
        callback();
      } else {
        return callback(new Error("请检查纳税人识别号"));
      }
    };
    return {
      className: "",
      form: {
        name: '',
        taxId: '',
        asId: this.getToken('asId'),
        email:'',
        address:'',
        phone:'',
        bankName:'',
        bankAccountNo:'',
      },
      rules: {
        name: [
          { required: true, message: '请输入客户名称', trigger: 'blur' },
        ],
        taxId: [
          { required: true, validator: checkTax, trigger: "blur" },
        ],
        
        email: [
            { required: true, message: '请输入账户邮箱', trigger: 'blur' },
            { pattern: '^[a-z0-9A-Z]+[- | a-z0-9A-Z . _]+@([a-z0-9A-Z]+(-[a-z0-9A-Z]+)?\\.)+[a-z]{2,}$', message: '账户不合法，请输入正确的账户邮箱', trigger: 'blur' }
          ],
        address: [
          { required: false, message: '请输入地址', trigger: 'blur' },
        ],
        phone: [{ validator: checkPhone, trigger: "blur" }],
      },
      isLoading: false,

    }
  },

  methods: {
//查询单个客户信息
 init(){  //初始化内容
 		this.isLoading = true;
    let data = {companyId: this.getToken('companyId'),id:this.$route.query.id, };
    console.log(data,'datadatadatadata')
    this.$store.dispatch("FindCustomerId", data).then(res => {
            this.isLoading = false;
           if (res.success) {
              this.form = res.data;
            } else {
              einvAlert.error("提示",res.msg)
            }
            }).catch(err => {
              this.isLoading = false;
          })
     
      
 },
  

// 保存
    submitForm() {
      this.$refs.form.validate((valid) => {
         if (valid) {
           let data = {...this.form, companyId: this.getToken('companyId'),userId: this.getToken('userId'), };
           this.$store.dispatch("editCustomer", data).then(res => {
            this.isLoading = false;
           if (res.success) {
             this.$router.push('/invoicing/customerInfo')
            } else {
              einvAlert.error("提示",res.msg)
            }
            }).catch(err => {
              this.isLoading = false;
          })
        }
      });
    },
    back() {
      this.$router.push('/invoicing/customerInfo');
    },

   
  },

  mounted() {
    // this.accountCapitalName();
    this.init();
  }

}
</script>
<style lang="less" scoped>
.edit_insurance {
  height: calc(100% - 60px);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    text-align: center;
    font-weight: 700;
    color: #333333;
    font-size: 18px;
    padding: 18px 0;
    width: calc(100% - 40px);
    border-bottom: 1px solid #dcdcdc;
  }
  .ruleForm {
    margin: 40px auto;
    .el-form-item__content {
      .el-input__inner {
        width: 420px;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
      }
      .el-input.is-disabled {
        .el-input__inner {
          background-color: #f6f6f6;
        }
      }
    }

    .btn {
      text-align: center;
      .el-button {
        width: 100px;
      }
      .cancel_btn {
        margin-left: 20px;
      }
    }
  }
}
.el-form-item__content {
  text-align: center !important;
}

</style>